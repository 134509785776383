class ProgressiveColorText extends HTMLElement {
    constructor() {
      super();

      this._progress = 0;
      this._reverse = this.getAttribute('reverse');

// Call the onScreenChange function on page load and on scroll or resize events
window.addEventListener('load', this.onScreenChange.bind(this));
window.addEventListener('scroll', this.onScreenChange.bind(this));
window.addEventListener('resize', this.onScreenChange.bind(this));
    } 

     // Function to update the progress of the element within the viewport
 updateProgress = (distanceFromTop, Top) => {
  const percentage = (distanceFromTop / (Top))*100;
  this._progress = this.clampValue(percentage, 0, 100);
}

      // Function to check if the element is currently visible within the viewport
 isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >=  (window.innerHeight / 4) - el.offsetHeight &&
    rect.left >= 0 &&
    rect.bottom <= (( window.innerHeight / 4 ) * 3) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

// Function to get the distance of the element from the top of the viewport
 getDistanceFromTop = (el) => {
  const rect = el.getBoundingClientRect();
  return rect.bottom - (window.innerHeight / 4)
}

clampValue = (num, min, max) => Math.min(Math.max(num, min), max);

// Function to output the distance from the top of the viewport
 onScreenChange = () => {
  if (this.isElementInViewport(this)) {
    this.updateProgress(this.getDistanceFromTop(this), window.innerHeight / 2);
     if(this._reverse == "true") {
       this.style.opacity =( 100 - this._progress) / 100;
     } else {
       this.style.opacity = this._progress / 100;
     }
    }
}

top = 450

connectedCallback () {
  this.updateProgress(this.getDistanceFromTop(this), window.innerHeight);
   if(this._reverse == "true") {
     this.style.opacity = ( 100 - this._progress) / 100;
   } else {
     this.style.opacity = this._progress / 100;
   }
}
  }
  
  customElements.define('progressive-color-text', ProgressiveColorText);